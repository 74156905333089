import React, { useRef, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import Select from "react-select";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



import {
  BASE_URL_IMAGE,
  END_POINT,
  METHODS,
  NumberSeprator,
  TabBarLabels,
  numberToEnglish,
} from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Button, Container, Form, Modal, Row, Col } from "react-bootstrap";
import Sticky from "react-sticky-el";
import VideoSwiper from "../../components/VideoSwiper";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import PartnersCarousel from "../../components/PartnersCarousel";
import Testimonial from "../../components/Testimonial";
import ProductPageHeader from "../../components/ProductPageHeader";
import ProductPageBlogSection from "../../components/ProductPageBlogSection";
import ProductPageDeatils from "../../components/ProductPageDeatils";
import ProductPagePolicySection from "../../components/ProductPagePolicySection";
import BottomPriceStripe from "../../components/BottomPriceStripe";
import LoginModal from "../../components/LoginModal";
const ActivityDetailScreen = () => {
  const { country, currency } = useSelector((store) => store.currencyReducer);
  const navigate = useNavigate();
  const { id } = useParams();
  let location = useLocation();
  const { checkin, checkout, room, selectedtab } = location?.state;
  const [show, setShow] = useState(false);
  const sharedurl = 'activities';

  const message = "Welcome to BharatChoice";
  const phoneNumber = "+66810908071";
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

  const [dateFrom, setdateFrom] = useState();
  const [dateTo, setdateTo] = useState();
  const [totalAmount, settotalAmount] = useState(0);
  const [hotel, setHotelData] = useState();
  const [selectedroomtype, setSelectedroomtype] = useState([]);
  const [hotepolicy, sethotepolicy] = useState();

  const [showModal, setShowModal] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const userData = useSelector((store) => store.userReducer.userData);

  const [LoginButtonVisible, setLoginButtonVisible] = useState(false);

  const isOffDay = (date) => {
    const offDayNumber = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    }[hotel?.offDay];

    return date.getDay() === offDayNumber;
  };

  // Filter function for the date picker to disable specific days
  const filterOffDays = (date) => {
    return date instanceof Date && !isOffDay(date);
  };


  const handleModalOpen = (item) => {
    setClickedItem(item);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  var getDateArray = function (start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(dt.toISOString().split("T")[0]);

      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };
  const [formData, setformData] = useState({
    checkin: "",
    checkout: "",
    noofGuest: 1,
    hotel_id: id,
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    hotelHandler();
    setdateFrom(checkin);
    setdateTo(checkout);
  }, []);
  useEffect(() => {
    if (dateFrom != undefined) {
      console.log("indsbjdsb");
      settotalAmount(0);
      setSelectedroomtype([]);
    }
  }, [dateFrom]);
  const hotelHandler = async () => {
    try {
      const result = await fetchData(
        END_POINT.GetActivityStockByActivityid + id
      );
      console.log(END_POINT.GetActivityStockByActivityid + id);
      if (result.success == true) {
        setHotelData(result.data[0]);
        const editorSta = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(result.data[0].policyExclusion)
          )
        );
        sethotepolicy(editorSta);
      }
    } catch (err) {
      console.log(err);
    }
  };


  function numberToWords(num) {
    if (num === 0) return "zero";

    const belowTwenty = [
      "", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine",
      "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen",
      "seventeen", "eighteen", "nineteen"
    ];
    const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
    const aboveThousand = ["", "thousand", "million", "billion", "trillion"];

    // Function to handle integer part
    function convertToWords(num) {
      if (num < 20) return belowTwenty[num];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 > 0 ? ` ${belowTwenty[num % 10]}` : "");
      if (num < 1000) return belowTwenty[Math.floor(num / 100)] + " hundred" + (num % 100 > 0 ? ` and ${convertToWords(num % 100)}` : "");
      for (let i = 0; i < aboveThousand.length; i++) {
        const unit = Math.pow(1000, i + 1);
        if (num < unit) return convertToWords(Math.floor(num / Math.pow(1000, i))) + " " + aboveThousand[i] + (num % Math.pow(1000, i) > 0 ? ` ${convertToWords(num % Math.pow(1000, i))}` : "");
      }
      return "";
    }

    // Handle only the integer part (discard decimal part)
    const integerPart = Math.floor(num); // Extract integer part
    return convertToWords(integerPart).trim();
  }



  const adultHandler = async (item, value) => {
    const plus = value; // Get the selected number of rooms
    const newArray = [...selectedroomtype];
    const findindex = newArray.findIndex(
      (x) => x.activity_item_type_id === item._id
    );
    if (findindex == -1) {
      newArray.push({
        childNumber: 0,
        adultNumber: plus,
        activity_id: item.activity_id,
        activity_item_type_id: item._id,
        activity_stock_id: item.activity_stock_data._id,
        name: item.name,
        content: item.content,
        type: item.type,
        item_inclusion: item.item_inclusion,
        image: item.image,
        payment_mode: item?.activity_stock_data?.payment_mode,
        booking_type: item?.activity_stock_data?.booking_type,
        date_from: item?.activity_stock_data?.date_from,
        campaign_name: item?.activity_stock_data?.campaign_name,
        payment_option: item?.activity_stock_data?.payment_option,
        adultcost: item?.activity_stock_data?.adultcost,
        adultsell: item?.activity_stock_data?.adultsell,
        childcost: item?.activity_stock_data?.childcost,
        childsell: item?.activity_stock_data?.childsell,
      });
    } else {
      if (plus == 0 && newArray[findindex].childNumber == 0) {
        newArray.splice(findindex, 1);
      } else {
        newArray[findindex].adultNumber = plus;
      }
    }

    const totalScores = newArray.reduce(
      (previousScore, currentScore, index) =>
        previousScore +
        currentScore.adultNumber * currentScore.adultsell +
        currentScore.childsell * currentScore.childNumber,

      0
    );

    console.log("totalScores", newArray);

    settotalAmount(totalScores);
    if (totalScores == 0) {
      setSelectedroomtype([]);
    } else {
      setSelectedroomtype(newArray);
    }

    const totalNumberchild = newArray.reduce(
      (previousScore, currentScore, index) =>
        previousScore + currentScore.adultNumber + currentScore.childNumber,

      0
    );

    setformData({
      ...formData,
      noofGuest: totalNumberchild,
    });
  };
  const childHandler = (item, value) => {
    const plus = value; // Get the selected number of rooms
    const newArray = [...selectedroomtype];
    const findindex = newArray.findIndex(
      (x) => x.activity_item_type_id === item._id
    );
    if (findindex == -1) {
      newArray.push({
        childNumber: plus,
        adultNumber: 0,
        activity_id: item.activity_id,
        activity_item_type_id: item._id,
        activity_stock_id: item.activity_stock_data._id,
        name: item.name,
        content: item.content,
        type: item.type,
        item_inclusion: item.item_inclusion,
        image: item.image,
        payment_mode: item?.activity_stock_data?.payment_mode,
        booking_type: item?.activity_stock_data?.booking_type,
        date_from: item?.activity_stock_data?.date_from,
        campaign_name: item?.activity_stock_data?.campaign_name,
        payment_option: item?.activity_stock_data?.payment_option,
        adultcost: item?.activity_stock_data?.adultcost,
        adultsell: item?.activity_stock_data?.adultsell,
        childcost: item?.activity_stock_data?.childcost,
        childsell: item?.activity_stock_data?.childsell,
      });
    } else {
      if (plus == 0 && newArray[findindex].adultNumber == 0) {
        newArray.splice(findindex, 1);
      } else {
        newArray[findindex].childNumber = plus;
      }
    }

    const totalScores = newArray.reduce(
      (previousScore, currentScore, index) =>
        previousScore +
        currentScore.adultNumber * currentScore.adultsell +
        currentScore.childsell * currentScore.childNumber,

      0
    );

    console.log("totalScores", totalScores);

    settotalAmount(totalScores);
    if (totalScores == 0) {
      setSelectedroomtype([]);
    } else {
      setSelectedroomtype(newArray);
    }

    const totalNumberchild = newArray.reduce(
      (previousScore, currentScore, index) =>
        previousScore + currentScore.adultNumber + currentScore.childNumber,

      0
    );

    setformData({
      ...formData,
      noofGuest: totalNumberchild,
    });
  };
  const findTotalAdultnum = (id) => {
    const TotalAdultnum = selectedroomtype.find((itemselect) => {
      return itemselect.activity_item_type_id == id;
    });

    return TotalAdultnum == undefined ? 0 : TotalAdultnum;
  };
  const minCheckinDate = new Date().toISOString().split("T")[0];

  return (
    <div>

      <LoginModal
        LoginButtonVisible={LoginButtonVisible}
        LoginButton={() => {
          setLoginButtonVisible(false);
        }}
      />

      {/* Navbar */}
      {/* <Header /> */}
      <BottomPriceStripe
        dateFrom={dateFrom}
        // dateTo={dateTo}
        noofGuest={formData.noofGuest}
        totalAmount={totalAmount}
        ButtonPress={() => {
          if (totalAmount == 0) {
            return Swal.fire({
              title: "Select adults and childs",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "Ok",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
              showConfirmButton: false,
            });
          }
          var startDate = new Date(dateFrom); //YYYY-MM-DD
          var endDate = new Date(dateTo); //YYYY-MM-DD
          const dates = getDateArray(startDate, endDate);

          navigate("/booking/" + selectedtab + "/" + id, {
            state: {
              selectedtab: selectedtab,
              data: selectedroomtype,
              totalAmount: totalAmount,
              dates: dates,
              guest: formData.noofGuest,
            },
          });
        }}
      />
      <ProductPageHeader hotel={hotel} sharedurl={sharedurl} />

      <div className="container">
        <div className="row">
          <div className="col-lg-7 md-7 sm-12">
            <p className="" style={{ fontSize: 20 }}>
              {hotel?.about}
            </p>
            <h3 style={{ color: "black", marginTop: "15px" }}>
              What this place offers
            </h3>
            <div className="row">
              {hotel?.placeoffer?.map((item, index) => {
                return (
                  <div className="col-lg-6 md-6 sm-12">
                    <span className="amenties">
                      <img src="/assets/img/star.svg" className="img-fluid" />
                      {item}
                    </span>
                  </div>
                );
              })}
            </div>

            <ProductPageDeatils
              keyName={"activity_stock_data"}
              keyNameSearch={"activity_item_type_id"}
              data={hotel?.activity_stock_data}
              selectedroomtype={selectedroomtype}
              adultHandler={(item, value) => {
                adultHandler(item, value);
              }}
              childHandler={(item, value) => {
                childHandler(item, value);
              }}
              productId={id}
              type={TabBarLabels.Activities}
            />
          </div>
          <Sticky className="col-lg-4 md-4 sm-12 don">
            <div className=" booking-dialog-box">
              <div class="form-group row justify-content-center mx-auto">
                <div className="form-element-each check-in col-12">
                  <span>Select Date </span>
                  {/* <input
                    type="date"
                    id="checkin"
                    name="checkin"
                    className="form-control form-element-style"
                    min={minCheckinDate}
                    value={dateFrom}
                    onChange={(e) => setdateFrom(e.target.value)}

                  /> */}


                  <DatePicker
                    selected={dateFrom ? new Date(dateFrom) : null}
                    onChange={(date) => {
                      const formattedDate = moment(date).format('YYYY-MM-DD');
                      setdateFrom(formattedDate); // Store the formatted date
                    }}
                    filterDate={filterOffDays}
                    minDate={minCheckinDate}
                    dateFormat="yyyy-MM-dd"
                    className="form-control form-element-style"
                    id="checkin"
                    name="checkin"
                  />


                </div>
              </div>

              {userData == "" ? (
                <Button
                  type="button"
                  className="sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                  onClick={() => {
                    // handleSubmit("tokn_test_5x21ntclsadbhmtu5ks");
                    setLoginButtonVisible(true);
                  }}
                >
                  Book now
                  <b></b>
                </Button>

              ) : (
                <button
                  type="button"
                  class="btn sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                  onClick={() => {
                    if (totalAmount == 0) {
                      return Swal.fire({
                        title: "Select adults and childs",
                        icon: "warning",
                        showCancelButton: true,
                        cancelButtonText: "Ok",
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes",
                        showConfirmButton: false,
                      });
                    }
                    var startDate = new Date(dateFrom); //YYYY-MM-DD
                    var endDate = new Date(dateTo); //YYYY-MM-DD
                    const dates = getDateArray(startDate, endDate);

                    navigate("/booking/" + selectedtab + "/" + id, {
                      state: {
                        selectedtab: selectedtab,
                        data: selectedroomtype,
                        totalAmount: totalAmount,
                        dates: dates,
                        Activitydate: dateFrom,
                        guest: formData.noofGuest,
                      },
                    });
                  }}
                >
                  Book now
                </button>
              )}



              {/* <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                <Button
                  type="button"
                  className="sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                >
                  <b> Whatsapp Us </b>
                </Button>
              </a> */}
              <div className="d-flex justify-content-between align-items-center totalrow">
                <span style={{ color: "#797979", fontSize: "1.5rem" }}>
                  Total
                </span>
                <span
                  style={{
                    fontSize: "2rem",
                    fontWeight: "550",
                  }}
                >
                  {NumberSeprator(Math.floor(totalAmount * currency[country]))}{" "}
                  {country}
                </span>
              </div>
              <span
                style={{
                  marginTop: "1rem",
                  display: "block",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {numberToWords(Math.floor(totalAmount * currency[country]))}
              </span>
              <span
                style={{
                  marginTop: "1rem",
                  display: "block",
                  textAlign: "center",
                }}
              >
                Tax and Service charge included
              </span>
              <div className="mt-2">
                <div class="booking-text1 desktop">
                  <div className="mt-2 d-flex justify-content-center">
                    <div class="booking-text1 desktop">
                      <ul className="ul-list">
                        <li className="ul-list-li">
                          <i class="far fa-clock blue-colr ul-li-i"></i> It only
                          takes 2 minutes
                        </li>
                        <li className="ul-list-li">
                          <i class="fas fa-percent blue-colr ul-li-i"></i>{" "}
                          Include all Taxes &amp; Service charge
                        </li>
                        <li className="ul-list-li">
                          <i class="fas fa-eye-slash blue-colr ul-li-i"></i> No
                          Hidden Cost
                        </li>
                        <li className="ul-list-li">
                          <i class="far fa-credit-card blue-colr ul-li-i"></i>{" "}
                          No Booking or Credit card fees!!
                        </li>
                        <li className="ul-list-li">
                          <i class="fas fa-mobile-alt blue-colr ul-li-i"></i>
                          Show mobile or printed voucher
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
        </div>
      </div>



      {/* {hotel?.activity_stock_data.map((item, index) => {
        // const shouldRenderCard = item.activity_stock_data.some(
        //   (itemroomtype) => itemroomtype.campaign_name
        // );

        if (item.activity_stock_data == undefined) {
          return null;
        }
        // const TotalAdultnum =
        //   selectedroomtype.length > 0 &&
        //   selectedroomtype.find((itemselect) => {
        //     return itemselect.activity_item_type_id == item._id;
        //   });
        const TotalAdultnum = findTotalAdultnum(item._id);
        // console.log("TotalAdultnum", item);
        return (
          <div className="services-box">
            <Row>
              <div class="col-md-4 col-6 text-center hotel-text1 hotel-text2 p-2">
                <div class="cus-dinner-img">
                  <img src={BASE_URL_IMAGE + item.image} class="img-fluid" />
                
                  <button
                    className="btn wish-list-btn"
                    onClick={() => handleModalOpen(item)}
                  >
                    MORE DETAILS <i class="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-8 col-6 hotel-text1 hotel-text3 ">
                <div class="cus-dinner-text desktop">
                  <h3>{item.name}</h3>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="cus-dinner-box desktop">
                      <p class="free free1">
                        <i class="fas fa-check"></i> Instant Confirmation{" "}
                        <span>
                          <img
                            src="https://bharatchoice.world/front-end/images/arrow.png"
                            style={{ width: "11px", height: "auto" }}
                          />
                        </span>
                      </p>

                      <p class="free free1">
                        <i class="fas fa-check"></i> Availability Today
                      </p>

                      <p class="free free1">
                        <i class="fas fa-check"></i> Best rate Guarantee
                      </p>

                      <p class="free free1">
                        <i class="fas fa-check"></i> No Cancellation
                      </p>
                    </div>
                  </div>

                  <div class="col-md-6 hotel-text1">
                    <h5>Best Price for Today's</h5>
                    <div class="prices">
                      <h6 class="mr-2">
                        ADULT
                        <span>
                          <span>
                            {NumberSeprator(
                              Math.floor(
                                item?.activity_stock_data?.adultcost *
                                  currency[country]
                              )
                            )}
                            {" " + country}
                          </span>
                        </span>
                      </h6>

                      <input
                        type="hidden"
                        value="20"
                        id="adultPrice46"
                        name="adultPrice"
                      />

                      <div id="field2" class="qty">
                        <button
                          type="button"
                          id="sub2"
                          value="46"
                          class="sub2 set_size priceIncrAdult"
                          onClick={(e) => {
                            const value =
                              TotalAdultnum == undefined ||
                              TotalAdultnum == false
                                ? 0
                                : TotalAdultnum.adultNumber;
                            if (value != 0) {
                              adultHandler(item, value - 1);
                            }
                          }}
                        >
                          -
                        </button>

                        <input
                          type="text"
                          id="adult_value46"
                          name="adult_value"
                          class="adult_value"
                          value={
                            TotalAdultnum == undefined || TotalAdultnum == false
                              ? 0
                              : TotalAdultnum?.adultNumber
                          }
                          readonly=""
                        />

                        <button
                          type="button"
                          id="add2"
                          value="46"
                          class="set_size priceDescAdult"
                          onClick={(e) => {
                            const value =
                              TotalAdultnum == undefined ||
                              TotalAdultnum == false
                                ? 0
                                : TotalAdultnum.adultNumber;
                            adultHandler(item, value + 1);
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <div class="prices mt-2">
                      <h6 class="mr-2">
                        CHILD
                        <span>
                          {NumberSeprator(
                            Math.floor(
                              item?.activity_stock_data?.childcost *
                                currency[country]
                            )
                          )}
                          {" " + country}
                        </span>
                      </h6>
                      <input
                        type="hidden"
                        name="child_sell"
                        id="child_sell"
                        value="12"
                      />
                      <div id="field2" class="qty">
                        <button
                          type="button"
                          id="sub2"
                          value="46"
                          class="sub2 set_size priceIncrAdult"
                          onClick={(e) => {
                            const value =
                              TotalAdultnum == undefined ||
                              TotalAdultnum == false
                                ? 0
                                : TotalAdultnum.childNumber;
                            if (value != 0) {
                              childHandler(item, value - 1);
                            }
                          }}
                        >
                          -
                        </button>

                        <input
                          type="text"
                          id="adult_value46"
                          name="adult_value"
                          class="adult_value"
                          value={
                            TotalAdultnum == undefined || TotalAdultnum == false
                              ? 0
                              : TotalAdultnum?.childNumber
                          }
                          readonly=""
                        />

                        <button
                          type="button"
                          id="add2"
                          value="46"
                          class="set_size priceDescAdult"
                          onClick={(e) => {
                            const value =
                              TotalAdultnum == undefined ||
                              TotalAdultnum == false
                                ? 0
                                : TotalAdultnum.childNumber;
                            childHandler(item, value + 1);
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <p class="text">{item.content}</p>
                    <div
                      class="spinner-border text-danger itemLoader46"
                      role="status"
                      style={{ display: "none" }}
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        );
      })} */}
      {/* Modal */}
      {/* <Modal
        show={showModal}
        onHide={handleModalClose}
        style={{ maxWidth: "none" }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {clickedItem && (
            <div>
              

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Dinning description: </b> {clickedItem.content}
              </span>

             

              <span className="amenties">
                <img
                  src="/assets/img/icon-room-size.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                <b> Dinning Type: </b> {clickedItem.type}
              </span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/*Yellow Carousel  */}
      <ProductPageBlogSection
        title={"Your holiday, our adventure"}
        description={`Let us guide you make your holiday the most fun and memorable one yet. We'll take you to all the best spots, plan all the activities, and make sure you have everything you need to relax and enjoy yourself`}
        data={[]}
        pageType={TabBarLabels.Activities}
      />

      {/* Location Details */}
      <ProductPagePolicySection
        location={{
          longitude: hotel != undefined ? hotel?.longitude : "",
          latitude: hotel != undefined ? hotel?.latitude : "",
        }}
        hotepolicy={hotel?.policyExclusion}
        id={id}
        heading={"Activity"}
      />

      {/* Testimonials Section */}
      <Testimonial type={"Activities"} />

      {/* PartnerShip Section */}
      <PartnersCarousel />
      {/* Footer */}
      {/* <Footer /> */}
    </div>
  );
};

export default ActivityDetailScreen;
