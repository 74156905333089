import React, { Component } from "react";
import Script from "react-load-script";

import "./Checkout.css";
import { PublicKeYOmise } from "../../../../Helper/helper";
// import { publicKey } from "../../../confidential/keys";
import { Button, Col, Container, Row, Accordion, Modal } from "react-bootstrap";

let OmiseCard;

export class Checkout extends Component {
  handleScriptLoad = () => {
    OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey: PublicKeYOmise,
      frameLabel: "Bharatchoice",
      submitLabel: "PAY NOW",
      currency: this.props.currency,
      // currency: "THB",
    });
  };

  creditCardConfigure = () => {
    OmiseCard.configure({
      defaultPaymentMethod: "credit_card",
      // otherPaymentMethods: ["mobile_banking_bbl", "mobile_banking_bay", "mobile_banking_kbank", "mobile_banking_ktb", "mobile_banking_scb", "promptpay", "googlepay"],
    });
    OmiseCard.configureButton("#credit-card");
    OmiseCard.attach();
  };

  omiseCardHandler = () => {
    OmiseCard.open({
      amount: this.props.totalAmount * 100,
      onCreateTokenSuccess: (token) => {
        console.log("Success! Payment token:", token);
        // console.log("Amount:", token);
        this.props.handleSubmit();
        this.props.createCreditCardCharge(token);
      },
      onFormClosed: () => { },
    });
  };

  handleClick = (e) => {
    e.preventDefault();
    this.creditCardConfigure();
    this.omiseCardHandler();
  };

  render() {
    return (
      <div>
        <Script
          url="https://cdn.omise.co/omise.js"
          onLoad={this.handleScriptLoad}
        />
        <form>
          <Button
            id="credit-card"
            type="button"
            className="sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
            onClick={this.handleClick}
          >
            Next step: Payment
          </Button>
        </form>
      </div>
    );
  }
}

export default Checkout;
