import React, { useEffect, useState } from "react";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';



import {
  END_POINT,
  BASE_URL_IMAGE,
  METHODS,
  NumberSeprator,
  mobileCode,
  numberToEnglish,
  TabBarLabels,
  BASE_URL,
} from "../../Helper/helper";
import { Header } from "../../components/Header";
import { Button, Col, Container, Row, Accordion, Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../../Helper/ApiCall";
import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../../components/LoginModal";
import CheckoutQRCode from "../../components/checkout/checkoutForm/omise-prebuilt-form/CheckoutQRCode";
import { Footer } from "../../components/Footer";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import ChekoutCreditCard from "../../components/checkout/checkoutForm/omise-prebuilt-form/CheckoutCreditCard";
import CheckoutInternetBanking from "../../components/checkout/checkoutForm/omise-prebuilt-form/CheckoutInternetBanking";

import ActivityLoader from "../../components/ActivityLoader";
import Sticky from "react-sticky-el/lib/basic-version";
const FoodPreference = (props) => {
  const navigate = useNavigate();
  let location = useLocation();
  const { id } = useParams();
  const { country, currency } = useSelector((store) => store.currencyReducer);
  const userData = useSelector((store) => store.userReducer.userData);
  const { data, totalAmount, dates, guest, selectedtab, Activitydate } = location?.state;
  const [hoteldata, setHotelData] = useState();
  const [hotepolicy, sethotepolicy] = useState();


  const [formData, setformData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    countryCode: "",
    hotel_info: "",
    lookingFor: "I'm the main guest",
    non_veg: [],
    veg: [],
    specialRequest: "",
    bedsAvilable: [],
    estimatedArrivvaltime: "",
  });
  const [GuestDetail, setGuestDetail] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileCode: "",
    mobile: "",
  });
  // const LookingFor = [
  //   "I'm the main guest",
  //   "I'm booking for someone else",
  //   "Booking for My Client (Travel Agents)",
  // ];

  const LookingFor = selectedtab === TabBarLabels.Activities
    ? ["I'm the main guest", "I'm booking for someone else"]
    : ["I'm the main guest", "I'm booking for someone else", "Booking for My Client (Travel Agents)"];

  const non_vegList = [
    "Standard Non-Vegetarian",
    "No Beef, No Pork",
    "No Pork",
    "No Beef",
    "No Seafood",
    "Non Vegetarian (Gluten Free)",
  ];
  const vegList = [
    "Standard Vegetarian",
    "Vegetarian (with Egg)",
    "Jain Meal",
    "Vegan Meal",
    "Vegetarian (Gluten Free)",
  ];
  const bedsAvilable = [
    "Perfer extra-large double bed",
    "Perfer Twin Bed",
    "Perfer Conecting Room",
    "Perfer rooms close to each other",
    "Perfer High Floor",
    "Perfer Baby cott in Room",
  ];
  const estimatedArrivvaltimelist = [
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];

  const [basePrice, setBasePrice] = useState(0);
  const [vatcharge, setvatcharge] = useState(0);
  const [servicecharge, setservicecharge] = useState(0);
  const [finaltotal, setfinaltotal] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [LoginButtonVisible, setLoginButtonVisible] = useState(false);

  let totalExtraBeds = 0;
  let totalRooms = 0;

  const message = "Welcome to BharatChoice";
  const phoneNumber = "+66810908071";
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;


  if (data) {
    data.forEach((item) => {
      totalExtraBeds += item.extrbed;
      totalRooms += item.room;
    });
  }
  let childNumber = 0;
  let adultNumber = 0;
  let selected_No_Pax = 0;
  let adultNumberVehicle = 0;


  if (data && data.length > 0) {

    console.log("data:");
    console.log(data);


    data.forEach((item) => {

      console.log(item);
      const firstItem = data[0] || {};
      const secondItem = data[1] || {};

      // adultNumber += item.adultNumber;
      adultNumber = firstItem.adultNumber || 0;
      adultNumberVehicle = secondItem.adultNumber || 0;
      childNumber += item.childNumber || 0;

      // selected_No_Pax += item.selected_No_Pax.numberPax;
      selected_No_Pax +=
        (item.selected_No_Pax && item.selected_No_Pax.numberPax) || 0;
    });

  }
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const guestDetailhandleChange = (e) => {
    setGuestDetail({
      ...GuestDetail,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    props.funcFoot(false);
    return () => {
      props.funcFoot(true);
    };
  });
  useEffect(() => {
    setformData({
      ...formData,
      firstName: userData != "" ? userData.firstName : "",
      lastName: userData != "" ? userData.lastName : "",
      email: userData != "" ? userData.email : "",
      mobile: userData != "" ? userData.mobile : "",
    });
    // const vatchargeAmount = (totalAmount * 7) / 100;
    // const servicechargeAmount = (totalAmount * 10) / 100;


    const basePriceCalc = totalAmount / 1.177;
    const serviceChargeCalc = basePriceCalc * 0.10;
    const vatChargeCalc = (basePriceCalc + serviceChargeCalc) * 0.07;

    setBasePrice(basePriceCalc.toFixed(2));
    setservicecharge(serviceChargeCalc.toFixed(2));
    setvatcharge(vatChargeCalc.toFixed(2));
    setfinaltotal(totalAmount);

    console.log("final amount:");
    console.log(totalAmount);
    console.log(totalAmount * currency[country]);
    // setfinaltotal(totalAmount + vatchargeAmount + servicechargeAmount);
    hotelHandler();
  }, []);
  console.log(selectedtab);

  const hotelHandler = async () => {
    try {
      const finalEndpoint =
        selectedtab == TabBarLabels.Hotel
          ? END_POINT.GetHotelStockByHotelid
          : selectedtab == TabBarLabels.Dinning
            ? END_POINT.GetDinningStockByDinningid
            : selectedtab == TabBarLabels.Activities
              ? END_POINT.GetActivityStockByActivityid
              : selectedtab == TabBarLabels.Vehicles
                ? END_POINT.GetVehiclesStockByVehiclesid
                : selectedtab == TabBarLabels.Packages
                  ? END_POINT.GetPackageStockByPackageid
                  : null;
      const result = await fetchData(finalEndpoint + id);
      console.log(finalEndpoint, id);

      if (result.success == true) {
        console.log(result);
        setHotelData(result.data[0]);
        const editorSta = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(result.data[0].policyExclusion)
          )
        );
        sethotepolicy(editorSta);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (token) => {
    console.log("hello handelsubmit");

    var raw = JSON.stringify({
      type: selectedtab,
      checkin: dates[0],
      checkout: dates[dates.length - 1],
      noofGuest: guest,
      hotel_id: id,
      user_id: userData._id,
      user_email: userData.email,
      amount: Math.floor(totalAmount),
      room_detail: data,
      totalExtraBeds: totalExtraBeds,
      totalRooms: totalRooms,
      dates: dates,
      order_id: token,
      mealDetail: {
        ...formData,
        GuestDetail: { ...GuestDetail },
      },

      //dinning special

      childNumber: childNumber,
      adultNumber: adultNumber,
      selected_No_Pax: selected_No_Pax,
      adult_number_vehicle: adultNumberVehicle,
    });

    try {
      const finalEndpoint =
        selectedtab == TabBarLabels.Hotel
          ? END_POINT.HotelBooking
          : selectedtab == TabBarLabels.Dinning
            ? END_POINT.DinningPostBooking
            : selectedtab == TabBarLabels.Activities
              ? END_POINT.PostActivityBooking
              : selectedtab == TabBarLabels.Vehicles
                ? END_POINT.PostVehiclesBooking
                : selectedtab == TabBarLabels.Packages
                  ? END_POINT.PostPackagesBooking
                  : null;
      localStorage.setItem("bookingdata", raw);
      localStorage.setItem("bookingdataurl", finalEndpoint);

      // const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      // console.log("API response:", result);
      // setisLoading(false);
      // if (result.success == true) {
      //   navigate("/thank-you");
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const createCreditCardCharge = async (token) => {
    try {
      setisLoading(true);
      const raw = JSON.stringify({
        email: userData.email,
        name: userData.fullName,
        amount: totalAmount * 100,
        token: token,
      });

      const res = await fetchData(
        END_POINT.omiseCheckoutCreditCard,
        METHODS.POST,
        raw
      );
      console.log("dsvdsv", res);

      if (res.success == true && res.data) {
        localStorage.setItem("charge_id", res.data.charge_id);

        setShowModal(false);

        // navigate("/thank-you");
        window.location.href = res.data.authorizeUri;
        // handleSubmit(token);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      console.log(err);
      setisLoading(false);
    }
  };

  const createInternetBankingCharge = async (token) => {
    try {
      const raw = JSON.stringify({
        email: "akashtomar@quloe.com",
        name: "Akash",
        amount: totalAmount,
        token: token,
      });

      const res = await fetchData(
        END_POINT.omiseCheckoutInternetBanking,
        METHODS.POST,
        raw
      );
      console.log(res);
      if (res.success == true && res.authorize_uri != undefined) {
        // setShowModal(false);
        handleSubmit(token);
        // window.location.href = res.authorize_uri;
      }
    } catch (err) {
      console.log(err);
    }
  };


  const [options, setOptions] = useState(countryList().getData());

  const handleCountryChange = (selectedOption) => {
    setformData({ ...formData, countryCode: selectedOption.value });
  };

  const [phone, setPhone] = useState('');

  const customStyles = {
    control: (base) => ({
      ...base,
      border: 'none',
      padding: '5px',
      backgroundColor: 'transparent',
      fontSize: '14px',
      width: '180px',
    }),
    option: (base, { data }) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (base, { data }) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  // const formatOptionLabel = ({ label, value, customAbbreviation }) => (
  //   <div style={{ display: 'flex', alignItems: 'center' }}>
  //     <img
  //       src={`https://flagcdn.com/16x12/${value.toLowerCase()}.png`}
  //       alt={label}
  //       style={{ marginRight: 10 }}
  //     />
  //     {`${customAbbreviation} (${label})`}
  //   </div>
  // );

  const formatOptionLabel = ({ label, value }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: 10 }}>{label}</span>
      <span>({value === "IN" ? "+91" : ""})</span>
    </div>
  );


  function numberToWords(num) {
    if (num === 0) return "zero";

    const belowTwenty = [
      "", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine",
      "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen",
      "seventeen", "eighteen", "nineteen"
    ];
    const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
    const aboveThousand = ["", "thousand", "million", "billion", "trillion"];

    // Function to handle integer part
    function convertToWords(num) {
      if (num < 20) return belowTwenty[num];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 > 0 ? ` ${belowTwenty[num % 10]}` : "");
      if (num < 1000) return belowTwenty[Math.floor(num / 100)] + " hundred" + (num % 100 > 0 ? ` and ${convertToWords(num % 100)}` : "");
      for (let i = 0; i < aboveThousand.length; i++) {
        const unit = Math.pow(1000, i + 1);
        if (num < unit) return convertToWords(Math.floor(num / Math.pow(1000, i))) + " " + aboveThousand[i] + (num % Math.pow(1000, i) > 0 ? ` ${convertToWords(num % Math.pow(1000, i))}` : "");
      }
      return "";
    }

    // Handle only the integer part (discard decimal part)
    const integerPart = Math.floor(num); // Extract integer part
    return convertToWords(integerPart).trim();
  }


  useEffect(() => {
    console.log("Options:", options); // Log options to inspect the data structure
  }, [options]); // Runs when options are updated

  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}
      <ActivityLoader showModal={isLoading} />

      <LoginModal
        LoginButtonVisible={LoginButtonVisible}
        LoginButton={() => {
          setLoginButtonVisible(false);
        }}
      />
      <Container className="mt-4 ">
        <Row className="pt-4 pb-4 justify-content-between d-flex">
          <Col className="user-details-col" md={6} lg={6} sm={12}>
            <h4>
              Enter your details <span style={{ color: "#DA1414" }}>*</span>
            </h4>
            <span style={{ color: "#797979" }}>
              (Booking voucher & Receipt will be sent to this email)
            </span>
            <Row className="d-flex justify-content-between">
              <Col xs={6} md={6}>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>First name</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter First name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={6} md={6}>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>Last name</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter Last name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col xs={6} md={6}>
                <div style={{ marginRight: 5 }} className="form-element-each">
                  <span>Email</span>
                  <div className="d-flex align-items-center">
                    <input
                      style={{ border: "none" }}
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={6} md={6}>
                <div style={{ marginRight: 5, height: '67px' }} className="form-element-each">
                  {/* <span>Mobile number</span> */}
                  <div className="d-flex align-items-center">
                    {/* <input
                      style={{ border: "none" }}
                      placeholder="Enter Mobile number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                    /> */}

                    {/* <Select
                      placeholder={"Country Code"}
                      options={options.map((country) => ({
                        ...country,
                        customAbbreviation: country.label.split(' (')[0], // Extracting country abbreviation
                      }))}
                      onChange={handleCountryChange}
                      styles={customStyles}
                      formatOptionLabel={formatOptionLabel}
                      // defaultValue={options.find((option) => option.value === formData.countryCode)}
                      defaultValue={options.find((option) => option.value.includes("IN"))}

                    /> */}

                    <PhoneInput
                      country={'in'} // Default country to India
                      value={formData.countryCode} // Show both values
                      onChange={(phone, country) => { // Use second argument for country details
                        setformData({
                          ...formData,
                          mobilee: phone.replace(`+${country.dialCode}`, ""), // Remove country code from mobile
                          countryCode: `+${country.dialCode}`, // Store correct country code
                        });
                      }}
                      enableSearch={true}
                      inputStyle={{
                        width: '100px',
                        height: '40px',
                      }}
                    />


                    <input
                      style={{ border: "none" }}
                      placeholder="Enter Mobile number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <span style={{ color: "#797979" }}>
              Booking voucher will be sent to this email
            </span>
            <div className="pt-4">
              <h5>What are you booking for?</h5>

              {LookingFor.map((item) => {
                return (
                  <div className="d-flex align-items-center pt-2">
                    <input
                      className="radio-select"
                      type="radio"
                      checked={formData.lookingFor == item ? true : false}
                      onChange={(e) => {
                        setformData({
                          ...formData,
                          lookingFor: item,
                        });
                      }}
                    />
                    <span>{item}</span>
                  </div>
                );
              })}
            </div>

            {(selectedtab === TabBarLabels.Activities || selectedtab === TabBarLabels.Dining) && (
              <div className="pt-4">
                <h5>Enter Hotel Information if transportation selected</h5>

                <div className="d-flex align-items-center pt-2">
                  <input
                    style={{
                      border: "1px solid gray", // Gray border color
                      borderRadius: "15px", // Rounded corners
                      padding: "20px", // Optional: add some padding for better appearance
                      outline: "none", // Optional: remove the default outline on focus
                    }}
                    placeholder="Enter Hotel Information"
                    name="hotel_info"
                    value={formData.hotel_info}
                    onChange={handleChange}
                  />

                  {/* <textarea
        placeholder="Enter Details"
        style={{
          width: "100%",
          minHeight: "10rem",
          borderRadius: "0.5rem",
          padding: "1rem",
        }}
        name="specialRequest"
        value={formData.specialRequest}
        onChange={handleChange}
      ></textarea> */}
                </div>
              </div>
            )}



            {(formData.lookingFor == LookingFor[1] ||
              formData.lookingFor == LookingFor[2]) && (
                <Row className="d-flex justify-content-between">
                  <Col xs={6} md={6}>
                    <div style={{ marginRight: 5 }} className="form-element-each">
                      <span>Title</span>
                      <div className="d-flex align-items-center">
                        <select
                          className="form-control form-element-style"
                          placeholder="Title"
                          name="title"
                          value={GuestDetail.title}
                          onChange={guestDetailhandleChange}
                        >
                          <option value={"Mr"}>{"Mr"}</option>
                          <option value={"Mrs"}>{"Mrs"}</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div style={{ marginRight: 5 }} className="form-element-each">
                      <span>First name</span>
                      <div className="d-flex align-items-center">
                        <input
                          style={{ border: "none" }}
                          placeholder="Enter First name"
                          name="firstName"
                          value={GuestDetail.firstName}
                          onChange={guestDetailhandleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div style={{ marginRight: 5 }} className="form-element-each">
                      <span>Last name</span>
                      <div className="d-flex align-items-center">
                        <input
                          style={{ border: "none" }}
                          placeholder="Enter Last name"
                          name="lastName"
                          value={GuestDetail.lastName}
                          onChange={guestDetailhandleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div style={{ marginRight: 5 }} className="form-element-each">
                      <span>Email</span>
                      <div className="d-flex align-items-center">
                        <input
                          style={{ border: "none" }}
                          placeholder="Enter Email"
                          name="email"
                          value={GuestDetail.email}
                          onChange={guestDetailhandleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div style={{ marginRight: 5 }} className="form-element-each">
                      <span>Mobile number</span>
                      <div className="d-flex align-items-center">
                        <select
                          style={{ border: "none" }}
                          name="mobileCode"
                          value={GuestDetail.mobileCode}
                          onChange={guestDetailhandleChange}
                        >
                          <option value="" selected="">
                            Select
                          </option>
                          {mobileCode.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div style={{ marginRight: 5 }} className="form-element-each">
                      <span>Mobile number</span>
                      <div className="d-flex align-items-center">
                        <input
                          style={{ border: "none" }}
                          placeholder="Enter Mobile number"
                          name="mobile"
                          value={GuestDetail.mobile}
                          onChange={guestDetailhandleChange}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            {/* <div className="pt-4">
              <h4>
                Meal preference
                <span
                  style={{
                    color: "#797979",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  (during your stay)
                </span>
                <span style={{ color: "#DA1414" }}>*</span>
              </h4>
              <span style={{ color: "#797979" }}>
                Make Thai Trip guarantee's to provide your meals at the property
                as per your dietary requirement during your stay with us in
                Amazing Thailand. Bon Appétit
              </span>
            </div>
            <div className="pt-4">
              <h5>Non-Vegetarian Meal Preference</h5>
              <Row>
                {non_vegList.map((item, index) => {
                  return (
                    <Col lg={6} md={6} sm={6}>
                      <div className="d-flex align-items-center pt-2 preference-radio">
                        <input
                          className="radio-select"
                          type="checkbox"
                          style={{ width: "20px", height: "20px" }}
                          onChange={(e) => {
                            if (e.target.checked == true) {
                              const newArray = [...formData.non_veg];
                              newArray.splice(index, 0, item);
                              setformData({
                                ...formData,
                                non_veg: newArray,
                              });
                            } else {
                              const newArray = [...formData.non_veg];
                              newArray.splice(index, 1);
                              setformData({
                                ...formData,
                                non_veg: newArray,
                              });
                            }
                          }}
                        />
                        <span>{item}</span>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
            <div className="pt-4">
              <h5>Vegetarian Meal Preference</h5>
              <Row>
                {vegList.map((item, index) => {
                  return (
                    <Col lg={6} md={6} sm={6}>
                      <div className="d-flex align-items-center pt-2">
                        <input
                          className="radio-select"
                          type="checkbox"
                          style={{ width: "20px", height: "20px" }}
                          onChange={(e) => {
                            if (e.target.checked == true) {
                              const newArray = [...formData.veg];
                              newArray.splice(index, 0, item);
                              setformData({
                                ...formData,
                                veg: newArray,
                              });
                            } else {
                              const newArray = [...formData.veg];
                              newArray.splice(index, 1);
                              setformData({
                                ...formData,
                                veg: newArray,
                              });
                            }
                          }}
                        />
                        <span>{item}</span>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
            <div className="pt-4">
              <h4>Special Requests</h4>
              <span style={{ color: "#797979" }}>
                Special requests cannot be guaranteed - but the property will do
                its best to meet your needs. You can always make a special
                request after your booking is complete!
              </span>
              <span
                style={{
                  color: "#383838",
                  display: "block",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                Please write your requests in English or Thai (Optional)
              </span>
              <textarea
                placeholder="Enter Details"
                style={{
                  width: "100%",
                  minHeight: "10rem",
                  borderRadius: "0.5rem",
                  padding: "1rem",
                }}
                name="specialRequest"
                value={formData.specialRequest}
                onChange={handleChange}
              ></textarea>
            </div> */}
            {selectedtab == TabBarLabels.Hotel && (
              <>
                <div className="pt-4">
                  <h5>
                    Choose your bed{" "}
                    <span
                      style={{
                        color: "#797979",
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      (if available)
                    </span>
                  </h5>
                  <Row>
                    {bedsAvilable.map((item, index) => {
                      return (
                        <Col lg={6} md={6} sm={6}>
                          <div className="d-flex align-items-center pt-2">
                            <input
                              className="radio-select"
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  const newArray = [...formData.bedsAvilable];
                                  newArray.splice(index, 0, item);
                                  setformData({
                                    ...formData,
                                    bedsAvilable: newArray,
                                  });
                                } else {
                                  const newArray = [...formData.bedsAvilable];
                                  newArray.splice(index, 1);
                                  setformData({
                                    ...formData,
                                    bedsAvilable: newArray,
                                  });
                                }
                              }}
                            />
                            <span>{item}</span>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
                <div className="pt-4">
                  <h4>
                    Your arrival time{" "}
                    <span style={{ color: "#DA1414" }}>*</span>
                  </h4>
                  <span style={{ color: "#797979" }}>
                    (Booking voucher & Receipt will be sent to this email)
                  </span>
                  <div className="d-flex align-items-center pt-2">
                    <input className="radio-select" type="radio" />
                    <span>Your room will be ready for check-in at 14:00</span>
                  </div>
                  <div className="d-flex align-items-center pt-2 pb-4">
                    <input className="radio-select" type="radio" />
                    <span>24-hour front desk - Help whenever you need it!</span>
                  </div>
                  <h5>Add your estimated arrival time (Optional)</h5>
                  <Row>
                    <Col lg={8} md={8} sm={10}>
                      <div className="form-element-each w-60">
                        <span>Please select Thailand arrival time</span>
                        <div className="d-flex align-items-center">
                          <select
                            class="form-control form-element-style"
                            id="inputLocation"
                            name="estimatedArrivvaltime"
                            value={formData.estimatedArrivvaltime}
                            onChange={handleChange}
                          >
                            <option selected>Select</option>
                            {estimatedArrivvaltimelist.map((item) => {
                              return <option value={item}>{item}</option>;
                            })}
                          </select>
                          <i
                            class="fa-solid fa-angle-down"
                            style={{ color: "#858585" }}
                          ></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            <div className="pt-4">
              <h4>Policy & Exclusion</h4>
              <Editor toolbarHidden editorState={hotepolicy} readOnly />
            </div>
          </Col>

          <Col md={4} lg={4} sm={12} className="p-4 summary-box shadow">
            <div>
              <Row>
                <Col md={2} lg={2}>
                  <img
                    src={BASE_URL_IMAGE + hoteldata?.images}
                    className="img-fluid"
                  />
                </Col>

                <Col md={10} lg={10} className="px-1">
                  <h3>
                    <b>
                      {hoteldata?.name}, {hoteldata?.city}
                    </b>
                  </h3>
                  <p>{hoteldata?.address}</p>
                </Col>
              </Row>

              <h5 className="my-2">
                <b>Your trip summary - {selectedtab?.toUpperCase()}</b>
              </h5>
              <Row className="d-flex justify-content-between pb-1">
                <Col
                  md={12}
                  lg={12}
                  sm={12}
                  className="d-flex flex-row justify-content-between"
                >
                  <h6>
                    <b>Dates</b>
                  </h6>


                  {selectedtab == TabBarLabels.Hotel ? (
                    <p>{dates[0] + " to " + dates[dates.length - 1]}</p>
                  ) : (
                    <p>{dates[0] ? dates[0] : Activitydate}</p>

                  )}
                  {/* <p>{dates[0] + " to " + dates[dates.length - 1]}</p> */}
                </Col>
              </Row>
              {selectedtab == TabBarLabels.Hotel && (
                <Row className="d-flex justify-content-between pb-1">
                  <Col
                    md={12}
                    lg={12}
                    sm={12}
                    className="d-flex flex-row justify-content-between"
                  >
                    <h6>
                      <b>Nights</b>
                    </h6>
                    <p>
                      {data.reduce((acc, obj) => {
                        return acc + obj.room;
                      }, 0)}{" "}
                      nights
                    </p>
                  </Col>
                </Row>
              )}
              <Row className="d-flex justify-content-between pb-1">
                {selectedtab == TabBarLabels.Hotel ? (
                  <Col
                    md={12}
                    lg={12}
                    sm={12}
                    className="d-flex flex-row justify-content-between"
                  >
                    <h6>
                      <b>Guest(s)</b>
                    </h6>
                    <p>{guest}</p>
                  </Col>
                ) : (
                  <>
                    <Col md={3} lg={3}>
                      <h6>
                        <b>Guest(s)</b>
                      </h6>
                    </Col>
                    <Row className="d-flex justify-content-between pb-1">
                      <Col md={3} lg={3}>
                        <p>
                          {selectedtab == TabBarLabels.Packages
                            ? "No. of Packs"
                            : "Adult"}
                        </p>
                      </Col>
                      <Col md={3} lg={3}>
                        <p>
                          {selectedtab == TabBarLabels.Packages
                            ? selected_No_Pax
                            : adultNumber}
                        </p>
                      </Col>
                    </Row>



                    {(selectedtab === TabBarLabels.Vehicles || selectedtab === TabBarLabels.Packages) ? null : (
                      <Row className="d-flex justify-content-between pb-1">
                        <Col md={3} lg={3}>
                          <p>Child</p>
                        </Col>
                        <Col md={3} lg={3}>
                          <p>{childNumber}</p>
                        </Col>
                      </Row>
                    )}

                  </>
                )}
                {/* <Col md={1} lg={1}>
                <Button
                  className=""
                  style={{
                    color: "#FFCC00",
                    textDecoration: "underline",
                    background: "none",
                    border: "none",
                  }}
                >
                  Edit
                </Button>
              </Col> */}
              </Row>

              <Row className="d-flex justify-content-between pb-1">
                <Col md={6} lg={6}>
                  <h6>
                    <b>Price details</b>
                  </h6>
                </Col>
              </Row>
              {selectedtab == TabBarLabels.Hotel
                ? data.map((item, index) => {
                  return (
                    <Row className="d-flex justify-content-between pb-1">
                      <Col md={6} lg={6}>
                        <h6>{item.name}</h6>
                        <p>
                          {item.room} nights
                          <br />
                          {item.extrbed > 0 && (
                            <span>{item.extrbed} Extra Bed added</span>
                          )}
                        </p>
                      </Col>
                      <Col md={3} lg={3}>
                        <p>
                          {NumberSeprator(
                            (totalAmount * currency[country])
                          )}
                        </p>
                      </Col>
                    </Row>
                  );
                })
                : data.map((item, index) => {
                  return (
                    <Row className="d-flex justify-content-between pb-1">
                      <Col md={6} lg={6}>
                        <h6>{item.name}  </h6>
                        {/* <p>
                        {item.room} nights
                        <br />
                        {item.extrbed > 0 && (
                          <span>{item.extrbed} Extra Bed added</span>
                        )}
                      </p> */}
                      </Col>
                      <Col md={3} lg={3}>
                        <p>

                          {item.type === "Vehicle" ? (
                            selectedtab === TabBarLabels.Packages ? (
                              NumberSeprator(
                                (
                                  selected_No_Pax * item.sell * currency[country]
                                )
                              )
                            ) : (
                              NumberSeprator(
                                (item.adultsell * currency[country] * (adultNumberVehicle || 0)) +
                                (item.childsell * currency[country] * (adultNumberVehicle || 0))
                              )
                            )
                          ) : (
                            selectedtab === TabBarLabels.Packages ? (
                              NumberSeprator(
                                (
                                  selected_No_Pax * item.sell * currency[country]
                                )
                              )
                            ) : (
                              NumberSeprator(
                                (
                                  adultNumber * item.adultsell * currency[country] +
                                  childNumber * item.childsell * currency[country]
                                )
                              )
                            )
                          )}
                        </p>

                      </Col>
                    </Row>
                  );
                })}


              <Row className="d-flex justify-content-between pb-1">
                <Col md={6} lg={6}>
                  <h6 className="">
                    <b>Price summary</b>
                  </h6>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between pb-1">
                <Col>
                  <h6>Net Rate</h6>
                </Col>
                <Col md={3} lg={3}>
                  <span>
                    {/* {NumberSeprator(
                      Math.floor(totalAmount * currency[country])
                    )} */}
                    {NumberSeprator(
                      (basePrice * currency[country])
                    )}

                  </span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between pb-1">
                <Col>
                  <h6>7% Vat</h6>
                </Col>
                <Col md={3} lg={3}>
                  <span>
                    {NumberSeprator((vatcharge * currency[country]))}
                  </span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between pb-1">
                <Col>
                  <h6>10% Service Charge</h6>
                </Col>
                <Col md={3} lg={3}>
                  <span>
                    {NumberSeprator(
                      (servicecharge * currency[country])
                    )}
                  </span>
                </Col>
              </Row>

              <Row className="d-flex justify-content-between pb-1">
                <Col lg={6} md={6}>
                  <p style={{ fontSize: "1.4rem" }}>Total</p>
                </Col>
                <Col lg={6} md={6}>
                  <b style={{ fontSize: "1.4rem" }}>
                    {/* {NumberSeprator(
                      Math.floor((totalAmount * currency[country]) + (servicecharge * currency[country]) + (vatcharge * currency[country]))
                    )}
                    {""} */}
                    {NumberSeprator(
                      (finaltotal * currency[country])
                    )}
                    {" "}
                    {country}
                  </b>
                </Col>
              </Row>
              <span
                style={{
                  marginTop: "1rem",
                  display: "block",

                  textTransform: "capitalize",
                }}
              >
                {/* {numberToEnglish(Math.floor(totalAmount))} */}
                {numberToWords(finaltotal * currency[country])}
              </span>
              <span
                className="d-block py-4"
                style={{ fontSize: "0.6rem", letterSpacing: "0.01rem" }}
              >
                By clicking “Pay”, I have read and agree to the Terms &
                Conditions
              </span>
              <Row className="pb-1">
                <Col lg={12} md={12} sm={12}>
                  {userData == "" ? (
                    <Button
                      type="button"
                      className="sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                      onClick={() => {
                        // handleSubmit("tokn_test_5x21ntclsadbhmtu5ks");
                        setLoginButtonVisible(true);
                      }}
                    >
                      <b>Next step: Payment</b>
                    </Button>
                    // ) : (selectedtab == TabBarLabels.Hotel ||
                    //   selectedtab == TabBarLabels.Dinning) &&
                    //   formData.non_veg.length == 0 &&
                    //   formData.veg.length == 0 ? (
                    //   <Button
                    //     type="button"
                    //     className="sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                    //     onClick={() => {
                    //       Swal.fire({
                    //         title: "Select food",
                    //         icon: "warning",
                    //         showCancelButton: true,
                    //         cancelButtonText: "Ok",
                    //         confirmButtonColor: "#3085d6",
                    //         cancelButtonColor: "#d33",
                    //         confirmButtonText: "Yes",
                    //         showConfirmButton: false,
                    //       });
                    //     }}
                    //   >
                    //     <b>Next step: Payment</b>
                    //   </Button>
                  ) : (
                    <ChekoutCreditCard
                      totalAmount={(finaltotal * currency[country])}
                      currency={country}
                      createCreditCardCharge={createCreditCardCharge}
                      handleSubmit={handleSubmit}
                      data={data}
                    />
                  )}

                </Col>
              </Row>

              {/* <Row className="pb-1">
                <Col lg={12} md={12} sm={12}>
                  <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                    <Button
                      type="button"
                      className="sub-btn-form col-md-12 sm-12 lg-12 mt-2 mb-4"
                    >
                      <b> Whatsapp Us </b>
                    </Button>
                  </a>

                </Col>
              </Row> */}

            </div>
            {/* <Sticky></Sticky> */}
          </Col>
        </Row>
      </Container>

      <div className="whatsapp-icon">
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">

          <img src={`${BASE_URL}/assets/img/whatsaap.png`} alt="WhatsApp" />

        </a>
      </div>

      {/* Footer */}
      {/* <Footer /> */}
    </div>
  );
};
export default FoodPreference;
